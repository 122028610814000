// prod
import bataco from './products/bataco';
// import iichiko from './products/iichiko';
// import biscoff from './products/biscoff';
// import stdalfour from './products/stdalfour';
// import ikasumiaji from './products/ikasumiaji';
import basefood from './products/basefood';
import kaneka from './products/kaneka';

const config = {
  bataco,
  // biscoff,
  // iichiko,
  // stdalfour,
  // ikasumiaji,
  basefood,
  kaneka,
};

export default config;
