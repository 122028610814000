import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "approuter" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Closed = _resolveComponent("Closed")!
  const _component_BeforeOpen = _resolveComponent("BeforeOpen")!
  const _component_QRCode = _resolveComponent("QRCode")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Loading = _resolveComponent("Loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isClosed)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Closed)
        ]))
      : (_ctx.isbeforeOpen)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_BeforeOpen)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.isPC)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_QRCode)
                ]))
              : (_ctx.loadable)
                ? (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
                : (_openBlock(), _createBlock(_component_Loading, { key: 2 }))
          ]))
  ]))
}