import { createStore } from "vuex";
import { User } from "firebase/auth";

interface State {
  shopData: { [key: string]: any } | null;
  shops: any;
  // shops2: any;
  shopPres: any;
  fbUser: undefined | null | User;
  itemKey: number;
}
export default createStore<State>({
  state: {
    shopData: null,
    // shops: null,
    shops: {},
    shopPres: null,
    fbUser: undefined,
    itemKey: -1,
  },
  mutations: {
    setShopData(state: State, shopData: any) {
      state.shopData = shopData;
    },
    /*
    setShopStocks(state: State, shops: any) {
      state.shops = shops;
    },
    */
    setShopStocks(state: State, shops: any) {
      console.log(shops.key);
      const newState = { ...state };
      newState.shops[shops.key] = shops.data;
      state = newState;
    },
    setShopPres(state: State, shops: any) {
      state.shopPres = shops;
    },
    setFBUser(state: State, user: User) {
      state.fbUser = user;
    },
    setItemKey(state: State, key: number) {
      state.itemKey = key;
    },
  },
  actions: {},
  modules: {},
});
