import ItemConfigModel from "./item_config";

export default class productConfigModel {
  productId: string;
  config: any;

  productName: string;

  splashTop: string;
  splashBottom: string;
  ogpTitleSuffix: string;

  headerTitle: string;

  topImagePath: string;
  // pinImagePath: string;

  imageSize: {
    w: number;
    h: number;
  };

  liffId: string;
  liffClientId: string;
  liffPrefix: string;

  isbeforeOpen: boolean;
  isClosed: boolean;

  messageOnInStockStore: boolean;

  stockNotification: string;
  stockNotificationNA: string;

  maxFeedbackNumber: number;
  enableRequestComment: boolean;

  items: any[];
  hasItemMenu: boolean;

  shareTitle: string;
  shareMessage: string;

  enableRequest: boolean;
  requestingFooterRequestable: string;
  requestingFooterRequesting: string;
  requestingFooterReachedLimit: string;
  requestedRequestButton: string;
  enableRequestTitle: boolean;
  isDebug: boolean;

  constructor(_productId: string, _config: any) {
    this.productId = _productId;
    this.config = _config;
    this.productName = this.config.productName;
    this.splashTop = this.config.splashTop;
    this.splashBottom = this.config.splashBottom;
    this.ogpTitleSuffix = this.config.ogpTitleSuffix;

    this.headerTitle = [
      this.splashTop || "",
      this.productName || "",
      this.splashBottom || "",
      this.ogpTitleSuffix || "",
    ]
      .join("")
      .replace(/\n/g, "　");

    this.liffId = this.config["line"]["liffId"];
    this.liffClientId = this.config["line"]["clientId"];

    this.liffPrefix = "https://liff.line.me/" + this.liffId;

    this.topImagePath = "/product_image/" + this.productId + "-top.jpg";
    // this.pinImagePath = "/product_image/" + this.productId + "-pin@3x.png";
    this.imageSize = this.config.imageSize;

    this.isbeforeOpen = this.config.isbeforeOpen || false;
    this.isClosed = this.config.isClosed || false;

    this.messageOnInStockStore = this.config.messageOnInStockStore || false;

    this.stockNotification =
      this.config.stockNotification || "（過去3日間における発注実績）";
    this.stockNotificationNA =
      this.config.stockNotificationNA || "（過去3日間における発注実績）";

    this.maxFeedbackNumber = this.config.maxFeedbackNumber || 5;

    this.enableRequestComment = this.config.enableRequestComment || false;
    this.items =
      this.config.items && this.config.items.length > 0
        ? this.config.items.map(
            (item: any) => new ItemConfigModel(this.productId, item)
          )
        : [];

    this.hasItemMenu = this.config.items && this.config.items.length > 1;

    this.shareTitle = this.config.shareTitle;

    this.shareMessage = this.config.shareMessage || "在庫があるかもよー";

    this.enableRequest = this.config.enableRequest;
    this.requestingFooterRequestable = this.config.requestingFooterRequestable;
    this.requestingFooterRequesting = this.config.requestingFooterRequesting;
    this.requestingFooterReachedLimit =
      this.config.requestingFooterReachedLimit;

    this.requestedRequestButton =
      this.config.requestedRequestButton || "応援済み";

    this.enableRequestTitle =
      this.config.enableRequestTitle === undefined
        ? true
        : this.config.enableRequestTitle;

    this.isDebug = this.config.isDebug || false;
  }

  /*
  getScaledSize(googleMap: any) {
    if (this.imageSize) {
      return new googleMap.Size(this.imageSize.w, this.imageSize.h);
    }
    return new googleMap.Size(72, 80);
  }
  getIcon(googleMap: any) {
    return {
      url: this.pinImagePath,
      scaledSize: this.getScaledSize(googleMap),
    };
  }
  */
  getFMIcon(googleMap: any) {
    return {
      url: "/images/fm-pin.png",
      scaledSize: new googleMap.Size(72, 80),
    };
  }
}
