import moment from "moment-timezone";
import { db, analytics } from "@/utils/firebase";
import { collection, doc, addDoc, setDoc } from "firebase/firestore";

import liff from "@line/liff";
import { setCurrentScreen, logEvent } from "firebase/analytics";
import { serverTimestamp } from "firebase/firestore";

// https://firebase.google.com/docs/reference/js/firebase.analytics.EventName
// https://firebase.google.com/docs/reference/js/firebase.analytics.Analytics#logevent

// export declare type EventNameString = 'add_payment_info' | 'add_shipping_info' | 'add_to_cart' | 'add_to_wishlist' | 'begin_checkout' | 'checkout_progress' | 'exception' | 'generate_lead' | 'login' | 'page_view' | 'purchase' | 'refund' | 'remove_from_cart' | 'screen_view' | 'search' | 'select_content' | 'select_item' | 'select_promotion' | 'set_checkout_option' | 'share' | 'sign_up' | 'timing_complete' | 'view_cart' | 'view_item' | 'view_item_list' | 'view_promotion' | 'view_search_results';

const getPlace = (place: any) => {
  try {
    const searchResult = place
      ? {
          name: place.shopName,
          address: place.prefectureName + place.address,
          lat: place.latitude,
          lng: place.longitude,
        }
      : {};
    return searchResult;
  } catch (e) {
    return {};
  }
};
export const searchHistory = (
  search: string,
  productId: string,
  itemId: string,
  userId: string,
  place: any
) => {
  const date = moment().tz("Asia/Tokyo").format("YYYY-MM-DD");
  const month = moment().tz("Asia/Tokyo").format("YYYY-MM");
  const path = `products/${productId}/date/${date}/user/${userId}/searchLog`;
  const os = liff.getOS();
  const data = {
    word: search,
    searchResult: getPlace(place),
    shopId: place.shopId,
    userId,
    productId,
    itemId,
    os,
    date,
    month,
    createdAt: serverTimestamp(),
  };
  console.log(data);
  addDoc(collection(db, path), data);
  const analyticsData = {
    search_term: search,
  };
  // console.log(analyticsData);
  logEvent(analytics, "search", analyticsData);
};
export const currentLog = (
  lat: number,
  lng: number,
  address: string,
  productId: string,
  itemId: string,
  userId: string
) => {
  const date = moment().tz("Asia/Tokyo").format("YYYY-MM-DD");
  const month = moment().tz("Asia/Tokyo").format("YYYY-MM");
  const path = `products/${productId}/date/${date}/user/${userId}/currentLog`;
  const os = liff.getOS();
  const data = {
    lat,
    lng,
    userId,
    productId,
    itemId,
    address,
    os,
    date,
    month,
    createdAt: serverTimestamp(),
  };
  addDoc(collection(db, path), data);
};

export const userActionLog = (
  action: string,
  firestoreEvent: any,
  shop: any,
  productId: string,
  itemId: string,
  userId: string,
  inStock: boolean | null,
  isLanding: boolean,
  message: string
) => {
  const { shopId, shopName, prefectureCode } = shop;
  const date = moment().tz("Asia/Tokyo").format("YYYY-MM-DD");
  const month = moment().tz("Asia/Tokyo").format("YYYY-MM");
  const path = `products/${productId}/date/${date}/shop/${shopId}/user/${userId}/userLog`;
  const os = liff.getOS();
  const data = {
    action,
    shopId,
    shopName,
    prefectureCode,
    userId,
    productId,
    itemId,
    inStock,
    isLanding,
    message,
    os,
    date,
    month,
    createdAt: serverTimestamp(),
  };
  // console.log(data);
  addDoc(collection(db, path), data);
  const analyticsData = {
    content_id: shopId,
    content_type: productId,
    method: productId,
  };
  // console.log(path, data);
  // console.log(analyticsData);
  if (firestoreEvent) {
    logEvent(analytics, firestoreEvent, analyticsData);
  }
};

export const shareLog = (
  shop: any,
  productId: string,
  itemId: string,
  userId: string,
  inStock: boolean,
  isLanding: boolean
) => {
  userActionLog(
    "share",
    "share",
    shop,
    productId,
    itemId,
    userId,
    inStock,
    isLanding,
    ""
  );
};

export const openLog = (
  shop: any,
  productId: string,
  itemId: string,
  userId: string,
  inStock: boolean | null,
  isLanding: boolean
) => {
  userActionLog(
    "open",
    "view_item",
    shop,
    productId,
    itemId,
    userId,
    inStock,
    isLanding,
    ""
  );
};

export const googleMapLog = (
  shop: any,
  productId: string,
  itemId: string,
  userId: string,
  inStock: boolean,
  isLanding: boolean
) => {
  userActionLog(
    "googlemap",
    "googlemap",
    shop,
    productId,
    itemId,
    userId,
    inStock,
    isLanding,
    ""
  );
};
export const messageLog = (
  shop: any,
  productId: string,
  itemId: string,
  userId: string,
  message: string
) => {
  userActionLog(
    "message",
    null,
    shop,
    productId,
    itemId,
    userId,
    false,
    false,
    message
  );
};

export const actionDemandLog = (
  shop: any,
  productId: string,
  itemId: string,
  userId: string,
  message: string
) => {
  userActionLog(
    "demand",
    null,
    shop,
    productId,
    itemId,
    userId,
    false,
    false,
    message
  );
};

export const demandLog = async (
  shop: any,
  productId: string,
  itemId: string,
  userId: string,
  inStock: boolean
) => {
  const { shopId, shopName, prefectureCode } = shop;
  const date = moment().tz("Asia/Tokyo").format("YYYY-MM-DD");
  const month = moment().tz("Asia/Tokyo").format("YYYY-MM");
  const action = "demand";
  const data = {
    productId,
    itemId,
    userId,
    shopId,
    inStock,
    shopName,
    prefectureCode,
    date,
    month,
    action,
    createdAt: serverTimestamp(),
  };
  const path = `products/${productId}/date/${date}/shop/${shopId}/user/${userId}/actionLog/${action}`;
  await setDoc(doc(db, path), data);
  // console.log(path, data);
  const analyticsData = {
    items: [
      {
        item_id: shopId,
        item_name: shopName,
        promotion_name: "demand",
        promotion_id: "demand",
        item_brand: productId,
      },
    ],
  };
  logEvent(analytics, "select_item", analyticsData);
};

export const appLog = async (
  productId: string,
  userId: string,
  action: string
) => {
  const os = liff.getOS();
  const url = location.pathname;
  const date = moment().tz("Asia/Tokyo").format("YYYY-MM-DD");
  const month = moment().tz("Asia/Tokyo").format("YYYY-MM");
  const data = {
    productId,
    userId,
    action,
    os,
    url,
    date,
    month,
    createdAt: serverTimestamp(),
  };

  const path = `products/${productId}/date/${date}/user/${userId}/appLog`;
  await addDoc(collection(db, path), data);
};

export const changePageLog = () => {
  setCurrentScreen(analytics, document.title);
  logEvent(analytics, "page_view", {
    page_location: location.href,
    page_path: location.pathname,
    page_title: document.title,
  });
  logEvent(analytics, "screen_view" as never, {
    app_name: "web",
    screen_name: document.title,
    // app_version: version
  });
};

export const loginLog = (user: any) => {
  // console.log(user.uid);
  logEvent(analytics, "login", {});
};
