
import { defineComponent } from "vue";
import Privacy from "../privacy.vue";

export default defineComponent({
  components: {
    Privacy,
  },
  setup(props, context) {
    const close = () => {
      context.emit("close");
    };
    return { close };
  },
});
