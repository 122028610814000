
import {
  defineComponent,
  ref,
  reactive,
  watch,
  computed,
  onMounted,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

// import MarkerClusterer from "@googlemaps/markerclustererplus";

import config from "@/config/config";
import Loading from "@/components/Loading.vue";
import Splash from "@/views/MapComponents/Splash.vue";

import Privacy from "@/views/MapComponents/Privacy.vue";
import Term from "@/views/MapComponents/Term.vue";

import CurrentPositionIcon from "@/views/MapComponents/CurrentPositionIcon.vue";
import CenterMarkerIcon from "@/views/MapComponents/CenterMarkerIcon.vue";

import Items from "@/views/MapComponents/Items.vue";
import Store from "@/views/MapComponents/Store.vue";

import * as AndroidMapUtils from "./AndroidMapUtils";

import * as logUtil from "../utils/logUtil";

import {
  useParams,
  useOSParams,
  useDateParams,
  useFirestoreUser,
  sleep,
} from "../utils/utils";

import {
  getInitCurrentPosition,
  getMapConfig,
  //  initSearch,
  initSearch2,
  getCurrentPosition,
  useFeedBacks,
  useCurrentPin,
  useGoogleMap,
  useItemConfigs,
  useIsReady,
  useShowStore,
  dataFlatten,
  useShopData,
  useUrl,
  useUrlParams,
  getDefaultItemKey,
  isDebug,
} from "./MapUtil";

import moment from "moment-timezone";
// https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/google.maps/index.d.ts

export default defineComponent({
  components: {
    Store,
    Splash,
    Privacy,
    Term,
    Items,
    Loading,
    CurrentPositionIcon,
    CenterMarkerIcon,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { productId, sharePathPrefix, productConfig } = useParams();

    const { isAndroid } = useOSParams();
    const { dateToday } = useDateParams();

    const { firebaseUser } = useFirestoreUser();

    const apiKey = config.google.mapApiKey;
    const pacInputRef = ref();
    const itemsRef = ref();
    const isInitializedMap = ref(false);

    const showPrivady = ref(false);
    const showTerm = ref(false);

    const currentPosition = getInitCurrentPosition();
    const mapStaticCenter = ref(currentPosition);
    const mapConfig = getMapConfig(currentPosition);

    const searchResult = ref([]);

    const loading = ref(false);

    let cacheKeys = {};
    let cacheData = {};

    const { storeShow, showStore, closeStore } = useShowStore();

    const { isActive, isReady, showSplash } = useIsReady();

    const { urlShopId, urlItemId } = useUrlParams();

    // const currentMarkers = reactive<({shopId: string, marker: google.maps.Marker})[]>([]);
    const currentMarkers: { shopId: string; marker: google.maps.Marker }[] = [];

    const { itemKey, itemConfig, itemId, hasItemMenu } =
      useItemConfigs(productConfig);

    const {
      selectedShopKey,
      inStockFlags,
      currentStoreHasStock,
      existStockData,
      shopData,
      selectedShop,
      meshMeta,
    } = useShopData(productConfig, itemConfig);

    const locationHash = computed(() => {
      // console.log("locationHash")
      return AndroidMapUtils.getLocationHash(
        meshMeta.value,
        mapStaticCenter.value
      );
    });

    const { initializeMap, map, google, googleMapRef, mapLoaded } =
      useGoogleMap(apiKey, mapConfig);

    const {
      isCurrentPosition,
      enableCurrentPositionButton,
      setCurrentPosition,
      centerChangeEvent,
    } = useCurrentPin(google, map, productId, itemId, firebaseUser);

    const { shopUrlPath } = useUrl(
      sharePathPrefix,
      productId,
      itemId,
      selectedShopKey
    );

    // loadActionLog is in mapAndUserAreActive
    const { feedbackObj, archivedLimit, loadActionLog } =
      useFeedBacks(
        firebaseUser,
        productId,
        dateToday,
        productConfig.maxFeedbackNumber
      );

    onMounted(() => {
      (async () => {
        // # This is intentional delay to keep showing the Splash in case of the loading is too short.
        await sleep(1);
        isActive.value = true;
      })();

      (async () => {
        await initializeMap();
        google.value.maps.event.addListener(
          map.value,
          "idle",
          centerChangeAndStopEvent
        );
        isInitializedMap.value = true;
        map.value.addListener("center_changed", centerChangeEvent);
      })();
    });
    const closeToggle = () => {
      if (itemsRef.value) {
        itemsRef.value.closeSwitch();
      }
    };
    // for change item
    const currentAllShopStocks = computed(() => {
      if (isAndroid) {
        if (
          itemConfig.value &&
          store.state.shops &&
          store.state.shops[itemId.value]
        ) {
          return AndroidMapUtils.getCurrentAllShops(
            store.state.shops[itemId.value].data.data,
            itemId.value,
            cacheKeys,
            cacheData
          );
        }
      }
      return {};
    });

    const shopStocks = computed(() => {
      if (isAndroid) {
        if (
          itemConfig.value &&
          store.state.shops &&
          store.state.shops[itemId.value]
        ) {
          const newLocation = [...locationHash.value];
          // TODO fix this called sometimes.
          return AndroidMapUtils.getShops(
            newLocation,
            store.state.shops[itemId.value].data.data,
            itemId.value,
            cacheKeys,
            cacheData
          );
        }
      } else {
        if (
          itemConfig.value &&
          store.state.shops &&
          store.state.shops[itemId.value]
        ) {
          return dataFlatten(store.state.shops[itemId.value].data);
        }
      }
      return {};
    });
    const mapShouldActive = computed(() => {
      return (
        mapLoaded.value &&
        Object.keys(shopStocks.value).length !== 0 &&
        shopData.value !== null
      );
    });
    const mapAndUserAreActive = computed(() => {
      return !!mapShouldActive.value && (!!firebaseUser.value || isDebug);
    });

    const centerChangeAndStopEvent = (e: any) => {
      if (map.value && map.value.getCenter()) {
        localStorage.setItem("lat", map.value.getCenter().lat());
        localStorage.setItem("lng", map.value.getCenter().lng());
      }

      mapStaticCenter.value = {
        lat: map.value.getCenter().lat(),
        lng: map.value.getCenter().lng(),
      };
      if (isAndroid && isReady.value) {
        const newLocation = [...locationHash.value];
        if (
          !AndroidMapUtils.checkCache(
            newLocation,
            cacheKeys,
            meshMeta.value.step
          )
        ) {
          addPins();
        }
      }
    };

    const shop2LatLng = () => {
      if (selectedShop.value) {
        return new google.value.maps.LatLng(
          selectedShop.value.latitude,
          selectedShop.value.longitude
        );
      } else {
        console.log(selectedShop.value, selectedShopKey.value);
      }
    };
    const setCenterPosition = () => {
      const newPos = shop2LatLng();
      if (newPos) {
        map.value.setCenter(newPos);
      }
      // TODO: else means no shop data. some error? (
    };
    const initShop = () => {
      if (urlShopId.value) {
        if (shopData.value[urlShopId.value]) {
          selectedShopKey.value = urlShopId.value;
          setCenterPosition();
          storeShow.value = true;
          // defaultPrefecture.value = shopData.value[shopId].prefectureCode;
        }
      }
    };
    const moveSelectedShop = () => {
      const newPos = shop2LatLng();
      if (newPos) {
        map.value.panTo(newPos);
        google.value.maps.event.addListenerOnce(map.value, "idle", showStore);
      }
      // TODO: else means no shop data. some error? (
    };
    const openWindow = (key: string) => {
      selectedShopKey.value = key;
      moveSelectedShop();
      // If user open window before loaded user, this log is skipped.
      // This is very rarely case.
      if (selectedShop.value) {
        logUtil.openLog(
          selectedShop.value,
          productId,
          itemId.value,
          firebaseUser?.value?.uid || "---",
          currentStoreHasStock.value,
          false
        );
      }
    };
    // need map and shops
    const addPins = () => {
      const now = Date.now() / 1000;

      const shopStockKeys = Object.keys(shopStocks.value);

      const markers = [];
      shopStockKeys.map((key) => {
        const shop = shopStocks.value[key];
        try {
          inStockFlags[key] =
            shop.length === 4 && shop[2] < now && now < shop[3];
          const position = {
            lat: shop[1],
            lng: shop[0],
          };
          const icon = inStockFlags[key]
            ? itemConfig.value.getIcon(google.value.maps)
            : productConfig.getFMIcon(google.value.maps);

          const marker = new google.value.maps.Marker({
            position,
            map: map.value,
            icon,
          });

          marker.addListener("click", () => {
            openWindow(key);
          });
          currentMarkers.push({
            shopId: key,
            marker,
          });
        } catch (e: any) {
          console.log(shop, e);
        }
      });
    };

    // mapAndUserAreActive safe
    const initShopLog = () => {
      if (selectedShopKey.value) {
        // When landing with android with share url, inventory data is incorrect because inventory data does not exist. It put null instead
        logUtil.openLog(
          selectedShop.value,
          productId,
          itemId.value,
          firebaseUser.value.uid,
          existStockData.value ? currentStoreHasStock.value : null,
          true
        );
      }
    };

    watch(mapShouldActive, (value) => {
      // console.log("mapShouldActive watch", value);
      if (value) {
        console.log(
          "mapShouldActive",
          moment().format("YYYY-MM-DD HH:mm:ss:SS")
        );
        initShop();
        addPins();
      }
    });
    store.commit(
      "setItemKey",
      getDefaultItemKey(productConfig, urlItemId.value)
    );
    const reloadMapData = () => {
      const fmicon = productConfig.getFMIcon(google.value.maps);
      const icon = itemConfig.value.getIcon(google.value.maps);

      loading.value = true;
      const now = Date.now() / 1000;

      const newStocks = isAndroid
        ? currentAllShopStocks.value
        : shopStocks.value;
      currentMarkers.map((marker) => {
        try {
          const shop = newStocks[marker.shopId];
          // inStockFlags[marker.shopId] = Math.random() * 2 > 1;

          inStockFlags[marker.shopId] = shop
            ? shop.length === 4 && shop[2] < now && now < shop[3]
            : false;
          marker.marker.setIcon(inStockFlags[marker.shopId] ? icon : fmicon);
        } catch (e) {
          console.log(e);
        }
      });
      setTimeout(() => {
        loading.value = false;
      }, 500);
    };

    watch(urlItemId, () => {
      const key = productConfig.items.findIndex((element) => {
        return element.id === urlItemId.value;
      });
      if (key > -1) {
        store.commit("setItemKey", key);
      }
    });
    watch(itemKey, () => {
      //TODO
      // cacheKeys = {};
      reloadMapData();
    });
    watch(urlShopId, (value) => {
      if (value) {
        if (selectedShopKey.value !== value) {
          storeShow.value = false;
          selectedShopKey.value = value;
          moveSelectedShop();
        }
      } else {
        storeShow.value = false;
        selectedShopKey.value = null;
      }
    });
    watch(shopUrlPath, (value) => {
      if (window.location.pathname !== value) {
        router.push(value);
      }
    });

    const close = () => {
      selectedShopKey.value = null;
      closeStore();
    };

    const selectSearchShop = (key: string) => {
      searchResult.value = [];
      const shop = store.state.shopData[key];
      logUtil.searchHistory(
        pacInputRef.value.value,
        productId,
        itemId.value,
        firebaseUser?.value?.uid || "---",
        shop
      );
      pacInputRef.value.value = "";
      openWindow(key);
    };
    // LOGLOG
    watch(firebaseUser, () => {
      if (firebaseUser.value) {
        logUtil.appLog(productId, firebaseUser.value.uid, "viewMap");
      }
    });
    watch(mapAndUserAreActive, () => {
      console.log(
        "mapAndUserAreActive",
        moment().format("YYYY-MM-DD HH:mm:ss:SS")
      );
      isReady.value = true; // then, hide splash
      if (!isDebug) {
        loadActionLog();
      }
      initShopLog();
      initSearch2(
        pacInputRef.value,
        google.value,
        map.value,
        productId,
        itemId,
        store,
        searchResult
      );
    });

    return {
      isReady,
      isInitializedMap,

      isAndroid,

      storeShow,
      loading,

      showPrivady,
      showTerm,

      googleMapRef,
      archivedLimit,
      pacInputRef,

      currentStoreHasStock,

      selectedShopKey,
      selectedShop,

      enableCurrentPositionButton,
      isCurrentPosition,

      feedbackObj,
      itemId,
      // computed
      showSplash,

      // methods
      close,
      setCurrentPosition,

      hasItemMenu,
      searchResult,

      selectSearchShop,

      itemsRef,
      closeToggle,
    };
  },
});
