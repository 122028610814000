const config = {
  productName: "ベースフード",
  splashTop: "みつけて",
  splashBottom: "",
  description: "33種類の栄養素がぎゅっ。ベースフードはここで買えるよ 🍪🍞",
  ogpImage: "basefood-ogp5.png",
  items: [
    {
      id: "fm",
      name: "ベースフード",
    },
  ],
  imageSize: {
    w: 72,
    h: 80
  },
  maxFeedbackNumber: 5,
  line: {
    liffId: "1656803128-vPxQp0R6",
    clientId: "1656803128"
  },
  stockNotification: "(※販売により売切の可能性あり)",
  stockNotificationNA: "※申し訳ございません",
  enableRequest: true,
  enableRequestComment: true,
  shareTitle: "ベースフード発見！",
  requestedRequestButton: "ありがとうございます!",
  requestingFooterRequestable: "",
  requestingFooterRequesting: "",
  requestingFooterReachedLimit: "",
  shareMessage: "33種類の栄養素がぎゅっ。ベースフードはここで買えるよ 🍪🍞",

  isSpecialEffect: false,
  
  termsSystemName: "みつけてベースフード",
  termsProductName: "BASE FOOD",
  termsExpirationDate: "令和4年1月31日から令和4年10月31日まで",
  termsFormUrl: "https://forms.gle/aS4VMKTdhV5DqyBN8",

  isClosed: true,
  
};
export default config;
