
import {
  defineComponent,
  watch,
  watchEffect,
  ref,
  onUnmounted,
  computed,
} from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import liff from "@line/liff";

// import * as Sentry from "@sentry/browser";
import config from "@/config/config";

import QRCode from "@/components/QRCode.vue";
import BeforeOpen from "@/components/BeforeOpen.vue";
import Closed from "@/components/Closed.vue";
import Loading from "@/components/Loading.vue";

import { signInWithCustomToken, onAuthStateChanged } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { setUserId } from "firebase/analytics";

import { auth, db, functions, analytics } from "@/utils/firebase";
import { doc, getDoc } from "firebase/firestore";

import * as logUtil from "@/utils/logUtil";

import { useFirestoreUser, useParams, useOSParams } from "../utils/utils";
import moment from "moment-timezone";

import { isDebug } from "./MapUtil";

import { User } from "firebase/auth";

export default defineComponent({
  components: {
    QRCode,
    BeforeOpen,
    Closed,
    Loading,
  },
  async setup() {
    const store = useStore();
    const {
      productId,
      productConfig,

      shareTopPath,

      liffStatePath,
      liffStateQuery,

      liffUrl,
    } = useParams();

    const { isAndroid, isIOS, isWeb } = useOSParams();
    const { firebaseUser } = useFirestoreUser();

    const route = useRoute();
    const router = useRouter();

    // # For Test
    // return { isPC: true};
    // await auth.signOut();

    const loadable = ref(false);
    let unregisterAuthObserver: null | (() => void) = null;
    const localFirebaseUser = ref<User | null | undefined>(undefined);
    const liffIdToken = ref<string | null | undefined>(undefined);
    const loadedUser = ref(false);

    if (config.target === "dev") {
      const VConsole = await import("vconsole");
      // console.log(new VConsole.default());
      new VConsole.default();
    }
    console.log("start", moment().format("YYYY-MM-DD HH:mm:ss:SS"));

    if (productConfig.isbeforeOpen) {
      return { isbeforeOpen: true };
    }
    if (productConfig.isClosed) {
      return { isClosed: true };
    }

    onUnmounted(() => {
      if (unregisterAuthObserver) {
        unregisterAuthObserver();
        unregisterAuthObserver = null;
      }
    });

    const fetchFromStorage = (filePath: string) => {
      const bucketName = config.bucketName;
      const fileUrl = `https://firebasestorage.googleapis.com/v0/b/${bucketName}/o/${encodeURIComponent(
        filePath
      )}?alt=media`;
      return fetch(fileUrl);
    };
    const getData = async (filePath: string, mutation: string) => {
      try {
        const jsonResponse = await fetchFromStorage(filePath);
        const jsonData = await jsonResponse.json();
        store.commit(mutation, jsonData);
        console.log("getData", moment().format("YYYY-MM-DD HH:mm:ss:SS"));
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const getStockData = async (
      filePath: string,
      mutation: string,
      key: string
    ) => {
      try {
        const jsonResponse = await fetchFromStorage(filePath);
        const jsonData = await jsonResponse.json();
        store.commit(mutation, { key, data: jsonData });
        console.log("getStockData", moment().format("YYYY-MM-DD HH:mm:ss:SS"));
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const loadProductData = async () => {
      const configDoc = await getDoc(doc(db, `products/${productId}`));
      return configDoc.data();
    };

    const getInitData = async (configData: any) => {
      if (isAndroid) {
        if (configData.shopMeshData) {
          Object.keys(configData.shopMeshData).map((key: string) => {
            getStockData(configData.shopMeshData[key], "setShopStocks", key);
          });
        }
        // getData(configData.shopMesh, "setShopStocks");
      } else {
        if (configData.shopData) {
          Object.keys(configData.shopData).map((key: string) => {
            getStockData(configData.shopData[key], "setShopStocks", key);
          });
        }
        // getData(configData.shops, "setShopStocks"); // stock data
      }
      getData(config.shopData, "setShopData"); // for map
    };

    const initFirebase = () => {
      console.log("firebase watch", moment().format("YYYY-MM-DD HH:mm:ss:SS"));
      unregisterAuthObserver = onAuthStateChanged(auth, async (user) => {
        console.log(
          "firebase update",
          moment().format("YYYY-MM-DD HH:mm:ss:SS")
        );
        localFirebaseUser.value = user;
        if (user) {
          store.commit("setFBUser", user);
          loadedUser.value = true;
          console.log("login: " + user.uid);
          setUserId(analytics, user.uid);
        } else {
          console.log("no user");
        }
      });
    };
    const liffInit = async () => {
      if (location.hostname === "localhost") {
        loadable.value = true;
        // return;
      }
      try {
        await liff.init({ liffId: productConfig.liffId });
        if (!liff.isLoggedIn()) {
          if (!isDebug) {
            liff.login();
          }
        }
        loadable.value = true;
        liffIdToken.value = await liff.getIDToken();
      } catch (e) {
        console.log("liff_error", e);
        //        Sentry.captureException(e);
      }
    };

    if (!liff.isInClient()) {
      if (liffStateQuery && liffStateQuery["redirect"]) {
        const path = liffStatePath || "";
        if (path.startsWith("/map")) {
          router.push(shareTopPath + path);
        }
        return { isPC: true };
      }
      if (isIOS || isAndroid) {
        // if (false) {
        const params = { ...route.query } as { [key: string]: string };
        params["redirect"] = "true";
        const qs = Object.keys(params)
          .map((key) => {
            return key + "=" + encodeURIComponent(params[key]);
          })
          .join("&");
        location.replace(liffUrl + "?" + qs);
        return;
      }
      if (isWeb && process.env.VUE_APP_ALLOWPC === "false") {
        return { isPC: true };
      }
    }

    await liffInit();
    // everything ok

    console.log("init end", moment().format("YYYY-MM-DD HH:mm:ss:SS"));
    // paranoia
    if (liff.isLoggedIn() || isDebug) {
      loadProductData().then((configData) => {
        getInitData(configData);
      });
      console.log("hash:", process.env.VUE_APP_GIT_SHA1); // NEVER REMOVE THIS LINE.
      initFirebase();
    }
    // initItems();

    // Todo test
    watch(firebaseUser, () => {
      logUtil.loginLog(firebaseUser);
    });
    logUtil.loginLog(firebaseUser);

    watchEffect(() => {
      if (localFirebaseUser !== undefined && liffIdToken.value !== undefined) {
        if (localFirebaseUser.value === null) {
          console.log("start func", moment().format("YYYY-MM-DD HH:mm:ss:SS"));
          functions.region = "asia-northeast1";
          const lineAuthenticate = httpsCallable(
            functions,
            "lineAuthenticate2"
          );
          (async () => {
            const os = liff.getOS();
            const url = location.pathname;
            const { data } = (await lineAuthenticate({
              clientId: productConfig.liffClientId,
              token: liffIdToken.value,
              productId,
              os,
              url,
            })) as { data: { customToken: string } };
            if (data.customToken) {
              // console.log("Firebase Login");
              const user = await signInWithCustomToken(auth, data.customToken);
            }
            console.log("end func", moment().format("YYYY-MM-DD HH:mm:ss:SS"));
          })();
        }
      }
    });

    return {
      isbeforeOpen: false,
      isClosed: false,
      loadable,
      loadedUser,
      firebaseUser,
      isPC: false,
    };
  },
});
