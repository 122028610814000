
import { defineComponent } from "vue";
import config from "@/config/config";
import { useRoute } from "vue-router";
import { useProductConfigForShare } from "../utils/utils";

import NotFound from "../components/NotFound.vue";

export default defineComponent({
  components: {
    NotFound,
  },
  setup() {
    const route = useRoute();
    const productConfig = useProductConfigForShare();
    if (productConfig === null) {
      return {
        notFound: true,
      };
    }
    const url = route.query.url as string;
    const shopname = route.query.shopname;
    const shareTitle = productConfig.shareTitle;

    const openLink = async () => {
      if (!window.navigator.share) {
        console.log("ご利用のブラウザでは共有できません。");
        return;
      }

      try {
        await window.navigator.share({
          title: shopname + "を共有！",
          text: productConfig.shareMessage,
          url,
        });
      } catch (e: any) {
        console.log(e.message);
      }
    };
    return {
      notFound: false,
      openLink,
      productConfig,
      shopname,
      shareTitle,
    };
  },
});
