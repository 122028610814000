<template>
  <div></div>
</template>

<script>
/*
    <img src="/images/icons/icon_c15.svg" class="inline w-6 ml-1" />
    <img src="/images/icons/icon_c18.svg" class="inline w-6 ml-1" />
    <img src="/images/icons/icon_c24.svg" class="inline w-6 ml-1" />
    <img src="/images/icons/icon_c30.svg" class="inline w-6 ml-1" />
    <img src="/images/icons/icon_c6.svg" class="inline w-6 ml-1" />
    <img src="/images/icons/icon_c17.svg" class="inline w-6 ml-1" />
    <img src="/images/icons/icon_c23_1.svg" class="inline w-6 ml-1" />
    <img src="/images/icons/icon_c25.svg" class="inline w-6 ml-1"/>
    <img src="/images/icons/icon_c5.svg" class="inline w-6 ml-1" />
    <img src="/images/icons/icon_c7.svg" class="inline w-6 ml-1" />
  */
</script>
