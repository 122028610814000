
import { defineComponent, computed, ref } from "vue";
import liff from "@line/liff";

import { db } from "@/utils/firebase";
import { doc, setDoc } from "firebase/firestore";
import { serverTimestamp } from "firebase/firestore";

import ParticleEffectButton from "./vue-particle-effect-buttons/index.vue";
import * as logUtil from "@/utils/logUtil";

import StoreInfo from "./StoreInfo.vue";
import StoreOrderState from "./StoreOrderState.vue";
import StoreShare from "./StoreShare.vue";

import {
  useParams,
  useFirestoreUser,
  pushdataPath,
  useOSParams,
} from "@/utils/utils";

import { useItemConfigs } from "../MapUtil";

export default defineComponent({
  components: {
    ParticleEffectButton,
    StoreInfo,
    StoreOrderState,
    StoreShare,
  },
  props: {
    selectedShop: {
      type: Object,
      required: true,
    },
    inStock: {
      type: Boolean,
      required: true,
    },
    archivedLimit: {
      type: Boolean,
      required: true,
    },
    requesting: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, context) {
    const animating = ref(false);
    const visible = ref(true);
    const message = ref("");
    const isFocus = ref(false);

    const postedMessage = ref(false);
    const postMessage = async () => {
      if (productConfig.enableRequestComment && message.value !== "") {
        postedMessage.value = true;

        await logUtil.demandLog(
          props.selectedShop,
          productId,
          itemId.value,
          firebaseUser.value.uid,
          true
        );
        await logUtil.messageLog(
          props.selectedShop,
          productId,
          itemId.value,
          firebaseUser.value.uid,
          message.value
        );
        message.value = "";
      }
    };
    const {
      productId,
      productConfig,

      sharePage,
      shareUrlFromOSShare,
    } = useParams();

    const { isAndroid } = useOSParams();
    const { itemConfig, itemId } = useItemConfigs(productConfig);

    const { firebaseUser } = useFirestoreUser();

    const requestable = computed(() => {
      return !props.requesting && !props.archivedLimit;
    });

    const {
      enableRequest,
      requestingFooterRequestable,
      requestingFooterRequesting,
      requestingFooterReachedLimit,
      shareMessage,
      shareTitle,
      messageOnInStockStore,
      enableRequestTitle,
    } = productConfig;

    const externalLink = () => {
      logUtil.googleMapLog(
        props.selectedShop,
        productId,
        itemId.value,
        firebaseUser.value.uid,
        props.inStock,
        false
      );

      const url = `https://www.google.com/maps/search/ファミリーマート${props.selectedShop.shopName}店/@${props.selectedShop.latitude},${props.selectedShop.longitude},14z`;
      liff.openWindow({
        url,
        external: true,
      });
      return;
    };
    const close = () => {
      if (!animating.value) {
        context.emit("closeWindow");
      }
    };
    const addDemand = async () => {
      if (!props.inStock && !props.requesting) {
        if (props.selectedShop && props.selectedShop.shopId) {
          await logUtil.demandLog(
            props.selectedShop,
            productId,
            itemId.value,
            firebaseUser.value.uid,
            false
          );
          if (productConfig.enableRequestComment && message.value !== "") {
            await logUtil.messageLog(
              props.selectedShop,
              productId,
              itemId.value,
              firebaseUser.value.uid,
              message.value
            );
          } else {
            await logUtil.actionDemandLog(
              props.selectedShop,
              productId,
              itemId.value,
              firebaseUser.value.uid,
              message.value
            );
          }
          const path = pushdataPath(
            props.selectedShop.shopId,
            productId,
            itemId.value,
            firebaseUser.value.uid
          );
          if (path) {
            console.log(path);
            const res = await setDoc(doc(db, path), {
              createdAt: serverTimestamp(),
            });
          }
        }
      }
    };
    const shareIOS = async () => {
      logUtil.shareLog(
        props.selectedShop,
        productId,
        itemId.value,
        firebaseUser.value.uid,
        props.inStock,
        false
      );
      if (!window.navigator.share) {
        console.log("ご利用のブラウザでは共有できません。");
        console.log(shareMessage);
        return;
      }

      try {
        await window.navigator.share({
          title: props.selectedShop.shopName + " 店を共有！",
          text: shareMessage,
          url: shareUrlFromOSShare,
        });
        console.log("共有が完了しました。");
      } catch (e: any) {
        console.log(e.message);
      }
    };

    const url = shareUrlFromOSShare;
    console.log(
      sharePage +
        "?url=" +
        encodeURIComponent(url) +
        "&shopname=" +
        encodeURIComponent(props.selectedShop.shopName + "店") +
        "&productId=" +
        productId
    );
    const shareAndroid = () => {
      const url = shareUrlFromOSShare;
      liff.openWindow({
        url:
          sharePage +
          "?url=" +
          encodeURIComponent(url) +
          "&shopname=" +
          encodeURIComponent(props.selectedShop.shopName + "店") +
          "&productId=" +
          productId,
        external: true,
      });
      logUtil.shareLog(
        props.selectedShop,
        productId,
        itemId.value,
        firebaseUser.value.uid,
        props.inStock,
        false
      );
      return;
    };

    const inputFocus = (e: any) => {
      setTimeout(() => {
        isFocus.value = true;
      }, 200);
    };
    const inputBlur = (e: any) => {
      setTimeout(() => {
        isFocus.value = false;
      }, 200);
    };

    const isAndroidWritting = computed(() => {
      return isFocus.value && isAndroid;
    });
    return {
      btnOps: {
        duration: 500,
        easing: "easeOutSine",
        type: "circle",
        canvasPadding: 200,
        size: 3,
        speed: 3,
        particlesAmountCoefficient: 3,
        oscillationCoefficient: 5,
        onComplete: () => {
          animating.value = false;
        },
        onBegin: () => {
          animating.value = true;
          addDemand();
        },
        animating,
        visible,
      },
      animating,
      // computed,
      requestable,
      enableRequest,
      requestingFooterRequestable,
      requestingFooterRequesting,
      requestingFooterReachedLimit,

      shareMessage,
      shareTitle,
      sharePage,
      shareUrlFromOSShare,

      messageOnInStockStore,
      enableRequestTitle,
      // methods,
      externalLink,
      close,
      addDemand,
      shareIOS,
      shareAndroid,

      productConfig,
      itemConfig,
      message,

      inputFocus,
      inputBlur,
      isFocus,
      isAndroidWritting,

      postMessage,
      postedMessage,
    };
  },
});
