import { createApp } from "vue";
// import VueMaterial from "vue-material";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// import "vue-material/dist/vue-material.min.css";
import "./assets/css/tailwind.css";
import "./assets/css/particle-effect-button.css";

const app = createApp(App);

// Vue.use(VueMaterial);
app.use(router);
app.use(store);
app.mount("#app");

if (VueQrcode.name) {
  app.component(VueQrcode.name, VueQrcode);
}
