import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Term = _resolveComponent("Term", true)!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
      class: "fixed z-50 top-0 left-0 pt-16 pb-40 bg-black bg-opacity-20 w-screen h-screen flex items-center justify-center"
    }, [
      _createElementVNode("div", {
        class: "bg-white max-h-full overflow-y-scroll shadow rounded-xl mx-6",
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
      }, [
        _createVNode(_component_Term)
      ])
    ])
  ]))
}