
import { defineComponent } from "vue";
import { useOSParams } from "@/utils/utils";

export default defineComponent({
  props: {
    shareTitle: {
      type: String,
      required: true,
    },
  },
  emits: ["shareIOS", "shareAndroid"],
  setup(props, context) {
    const { isIOS, isAndroid, isWeb } = useOSParams();

    const shareIOS = () => {
      context.emit("shareIOS");
    };
    const shareAndroid = () => {
      context.emit("shareAndroid");
    };

    return {
      isIOS,
      isAndroid,
      isWeb,

      shareIOS,
      shareAndroid,
    };
  },
});
