const config = {
  productName: "みつけてQ10ヨーグルト",
  items: [
    {
      id: "q10yogurt",
      name: "わたしのチカラQ10ヨーグルト",
    },
    {
      id: "q10yogurtdrink",
      name: "わたしのチカラQ10ヨーグルト ドリンクタイプ",
    },
  ],
  description: "近くのファミリーマートで Q10 ヨーグルト、Q10 ヨーグルトドリンクタイプ を探してみてね！",
  ogpImage: "kaneka-ogp2.jpg",
  imageSize: {
    w: 72,
    h: 80
  },
  maxFeedbackNumber: 5,
  line: {
    liffId: "1657312485-xY74D6ZB",
    clientId: "1657312485"
  },
  stockNotification: "※販売により売切れの可能性あり",
  stockNotificationNA: "※申し訳ございません",
  enableRequest: true,
  enableRequestComment: true,
  shareTitle: "",
  requestingFooterRequestable: "",
  requestingFooterRequesting: "",
  requestingFooterReachedLimit: "",
  shareMessage: "近くのファミリーマートで Q10 ヨーグルト、Q10 ヨーグルトドリンクタイプ を探してみてね！",
  isSpecialEffect: false,

  isDebug: false,
  isbeforeOpen: false,
  isClosed: true,

  enableRequestTitle: false,

  messageOnInStockStore: true,
  
  termsSystemName: "みつけてQ10ヨーグルト",
  termsProductName: "わたしのチカラQ10ヨーグルト",
  termsExpirationDate: "令和4年8月1日から令和5年6月30日まで",
  termsFormUrl: "https://forms.gle/55swQ3dsTeGoD2aW9",
};
export default config;
