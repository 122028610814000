const config = {
  productName: "バターコーヒー",
  splashTop: "",
  splashBottom: "探すさん",
  ogpTitleSuffix: "ロケーター",
  imageSize: {
    w: 72,
    h: 80
  },
  maxFeedbackNumber: 5,
  line: {
    liffId: "1655623984-bdwB9m7Z"
  },
  enableRequest: true,
  shareTitle: "イカスミゲット！",
  requestingFooterRequestable: "イカスミをかける\nᔦ՞ਊ՞ᔨ▂▅▇█▓▒░ｲｶｽﾐﾌﾞｰ",
  requestingFooterRequesting: "イカスミをかける\nᔦ՞ਊ՞ᔨ▂▅▇█▓▒░ｲｶｽﾐﾌﾞｰ",
  requestingFooterReachedLimit: "イカスミをかける\nᔦ՞ਊ՞ᔨ▂▅▇█▓▒░ｲｶｽﾐﾌﾞｰ",
  shareMessage: "ポテトチップスイカスミ味はイカが？ᔦ՞ਊ՞ᔨ▂▅▇█▓▒░ｲｶｽﾐﾌﾞｰ"
};
export default config;
