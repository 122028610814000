import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import config from "@/config/config";

initializeApp(config.firebaseConfig);

export const db = getFirestore();
export const auth = getAuth();
export const functions = getFunctions();
export const analytics = getAnalytics();
