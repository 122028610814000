
import { defineComponent } from "vue";

export default defineComponent({
  emmits: ["newsToggle"],
  setup(props, context) {
    const close = () => {
      context.emit("newsToggle");
    };
    return { close };
  },
});
