
import Loading from "@/components/Loading.vue";
import { defineComponent, watch } from "vue";
import { useRoute } from "vue-router";

import * as logUtil from "@/utils/logUtil";

export default defineComponent({
  components: {
    Loading,
  },
  setup() {
    const route = useRoute();

    logUtil.changePageLog();
    watch(route, () => {
      logUtil.changePageLog();
    });
    return {};
  },
});
