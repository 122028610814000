import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

import liff from "@line/liff";
import { DateTime } from "luxon";

import {
  DocumentSnapshot,
  QueryDocumentSnapshot,
  DocumentData,
} from "firebase/firestore";

import config from "@/config/config";

import productConfigModel from "@/models/product_config";

import {
  parseLiffState,
  getSharePathPrefix,
  getSharePath,
  getShareLiffPath,
} from "./internalUtils";

export const array2obj = (array: DocumentData[], key?: string) => {
  return array.reduce(
    (tmp: { [key: string]: DocumentData }, current: DocumentData) => {
      if (key) {
        tmp[current[key]] = current;
      } else {
        tmp[current.id] = current;
      }
      return tmp;
    },
    {}
  );
};

export const doc2data = (doc: DocumentSnapshot | QueryDocumentSnapshot) => {
  const data = doc.data() || {};
  data.id = doc.id;
  return data;
};

export const sleep = async (seconds: number) => {
  return await new Promise((resolve) => setTimeout(resolve, seconds * 1000));
};

export const params2quesryString = (params: any) => {
  const query = Object.keys(params)
    .sort((a: string, b: string) => {
      return a > b ? 1 : -1;
    })
    .map((key: string) => {
      return key + "=" + params[key];
    })
    .join("&");
  console.log(query);
  return query;
};

export const useParams = () => {
  const route = useRoute();

  const productId = (() => {
    const req = route.params.product;
    const products = Object.keys(config.products);
    return products.find((elem) => elem == req) || "bataco";
  })();

  const product_config = (config.products as any)[productId];

  const productConfig = new productConfigModel(productId, product_config);

  // for share
  // https:// productslocator.com
  const shareHost = config.host;

  // "/liff" or ""
  const sharePathPrefix = getSharePathPrefix(window.location.pathname);

  // /bataco/coffee/map/s/123
  const sharePath = getSharePath(window.location.pathname);

  // https:// productslocator.com/bataco/coffee/map/s/123
  const shareUrl = shareHost + sharePath;

  const shareUrlFromOSShare =
    shareUrl + "?utm_source=user&utm_medium=share&utm_campaign=" + productId;

  // https:// productslocator.com/bataco/share
  const sharePage = shareHost + "/" + productId + "/share";

  // /bataco
  const shareTopPath = "/" + productId;

  // liff
  const { liffStatePath, liffStateQuery } = parseLiffState(
    route.query["liff.state"] as string
  );

  // ???
  const shareLiffPath = getShareLiffPath(
    liffStatePath,
    liffStateQuery,
    sharePath
  );

  // https://productslocator.com/bataco
  const shareTopUrl = shareHost + "/" + productId;

  // "https://liff.line.me/" + this.liffId + "//coffee/map/s/123";
  const liffUrl = productConfig.liffPrefix + "/" + shareLiffPath;

  return {
    productId,

    productConfig,

    sharePage,
    shareUrl,
    shareUrlFromOSShare,
    shareTopPath,

    liffUrl,
    liffStatePath,
    liffStateQuery,

    shareTopUrl,
    sharePathPrefix,
  };
};

export const useOSParams = () => {
  //
  const os = liff.getOS();
  const isAndroid = os === "android";
  const isIOS = os === "ios";
  const isWeb = os === "web";
  return {
    os,
    isAndroid,
    isIOS,
    isWeb,
  };
};

export const useDateParams = () => {
  // not used
  const dateTime = DateTime.local();
  const monthToday = dateTime.toFormat("yyyy-MM");
  const dateToday = dateTime.toFormat("yyyy-MM-dd");
  return {
    dateToday,
  };
};

export const useProductConfigForShare = () => {
  const route = useRoute();

  const productName = (() => {
    const req = route.params.product;
    const products = Object.keys(config.products);
    return products.find((elem) => elem == req);
  })();

  const productId = (route.query.productId || productName) as string;
  const product_config = (config.products as any)[productId];
  if (!product_config) {
    return null;
  }
  const productConfig = new productConfigModel(productId, product_config);

  return productConfig;
};

export const useFirestoreUser = () => {
  const store = useStore();

  const firebaseUser = computed(() => {
    return store.state.fbUser;
  });

  return {
    firebaseUser,
  };
};

export const pushdataPath = (
  shopId: string,
  productId: string,
  itemId: string,
  userId: string
): string | null => {
  return userId
    ? `/products/${productId}/shopId/${shopId}/itemId/${itemId}/user/${userId}`
    : null;
};

export const useToggle = (defaultValue = false) => {
  const value = ref(defaultValue);
  const toggleOn = () => {
    value.value = true;
  };
  const toggleOff = () => {
    value.value = false;
  };
  const toggle = () => {
    value.value = !value.value;
  };
  return {
    value,
    toggleOn,
    toggleOff,
    toggle,
  };
};
