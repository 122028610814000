
import { defineComponent } from "vue";
import { useParams, useOSParams, params2quesryString } from "@/utils/utils";

export default defineComponent({
  setup() {
    const { productId, shareTopUrl, productConfig, liffUrl, shareUrl } =
      useParams();

    const { isIOS, isAndroid } = useOSParams();

    const param = {
      utm_source: "locator",
      utm_medium: "qrcode",
      utm_campaign: productId,
    };
    const liffParam = {
      utm_source: "locator",
      utm_medium: "button",
      utm_campaign: productId,
    };
    const QRCodeImagePath = productConfig.isDebug
      ? "/images/fm.jpg"
      : productConfig.topImagePath;

    const liffUrlQRCode = liffUrl + "?" + params2quesryString(liffParam);

    const shareTopUrlQRCode =
      (liffUrl || shareUrl) + "?" + params2quesryString(param);

    return {
      QRCodeImagePath,
      isAndroid,
      isIOS,
      liffUrlQRCode,
      shareTopUrlQRCode,
      shareTopUrl,
    };
  },
});
