// for getShops
const searchData = (
  locationHash: string[],
  itemId: string,
  data: any,
  cacheKey: string[]
): any[] => {
  const lat = locationHash.shift();
  const lng = locationHash.shift();
  if (lat === undefined || lng === undefined) {
    return [];
  }
  const newData = data[lat][lng];
  cacheKey.push(lat);
  cacheKey.push(lng);
  if (Array.isArray(newData)) {
    return newData;
  }
  return searchData(locationHash, itemId, newData, cacheKey);
};

export const getCurrentAllShops = (
  shopData: any,
  itemId: string,
  cacheKeys: { [key: string]: any },
  cacheData: { [key: string]: any }
) => {
  if (cacheData[itemId] === undefined) {
    cacheData[itemId] = {};
  }
  const ret = {};
  Object.keys(cacheKeys).map((key: string) => {
    if (!cacheData[itemId][key]) {
      const cacheKey: string[] = [];
      cacheData[itemId][key] = convJsonToData(
        searchData(key.split(""), itemId, shopData, cacheKey)
      );
      console.log(cacheData[itemId][key]);
    }
    Object.assign(ret, cacheData[itemId][key]);
  });
  console.log(cacheKeys);
  return ret;
};

const convJsonToData = (data: any) => {
  return data.reduce(
    (tmp: { [key: string]: number[] }, r: { [key: string]: any }) => {
      if (r.length > 3) {
        tmp[r[0]] = [r[1], r[2], r[3], r[4]];
      } else {
        tmp[r[0]] = [r[1], r[2]];
      }
      return tmp;
    },
    {}
  );
};

export const getShops = (
  locationHash: string[],
  shopData: any,
  itemId: string,
  cacheKeys: { [key: string]: any },
  cacheData: { [key: string]: any }
) => {
  try {
    const cacheKey: string[] = [];
    const ret = searchData(locationHash, itemId, shopData, cacheKey);
    if (cacheData[itemId] === undefined) {
      cacheData[itemId] = {};
    }
    if (cacheKeys[cacheKey.join("")]) {
      return cacheData[itemId][cacheKey.join("")];
    }

    cacheKeys[cacheKey.join("")] = true;

    const response = convJsonToData(ret);

    cacheData[itemId][cacheKey.join("")] = response;
    return response;
  } catch (e) {
    console.log(e);
  }
};

// for location hash
const sortFunc = (a: number, b: number): number => {
  return a === b ? 0 : a > b ? 1 : -1;
};

const deepSearch = (
  ret: string[],
  mapCenter: any,
  latitudes: number[],
  longitudes: number[],
  depth: number
): void => {
  // console.log(latitudes);
  const latCenter = (latitudes[0] + latitudes[1]) / 2;
  const lngCenter = (longitudes[0] + longitudes[1]) / 2;

  const latKey = mapCenter["lat"] > latCenter ? "1" : "0";
  ret.push(latKey);
  const lngKey = mapCenter["lng"] > lngCenter ? "1" : "0";
  ret.push(lngKey);

  if (depth < 8) {
    deepSearch(
      ret,
      mapCenter,
      [latCenter, latitudes[Number(latKey)]].sort(sortFunc),
      [lngCenter, longitudes[Number(lngKey)]].sort(sortFunc),
      depth + 1
    );
  }
};

export const getLocationHash = (
  meshMeta: { [key: string]: number[] },
  mapCenter: { lat: number; lng: number }
): string[] => {
  const lat = meshMeta["lat"];
  const lng = meshMeta["lng"];
  // const pos  = this.getCurrentCenter();
  const pos = mapCenter;

  const ret: string[] = [];
  deepSearch(ret, pos, lat, lng, 1);
  return ret;
};

export const checkCache = (
  locationHash: string[],
  cacheKeys: { [key: string]: any },
  step: number
) => {
  for (let i = 0; i < step; i++) {
    const key = locationHash.slice(0, (i + 1) * 2).join("");
    if (cacheKeys[key]) {
      return true;
    }
  }
  return false;
};
