
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    productConfig: {
      type: Object,
      required: true,
    },
    itemConfig: {
      type: Object,
      required: true,
    },
    inStock: {
      type: Boolean,
      required: true,
    },
  },
});
