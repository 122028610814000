export default class itemConfigModel {
  config: any;
  id: string;
  productId: string;
  name: string;

  topImagePath: string;
  pinImagePath: string;
  imageSize: {
    w: number;
    h: number;
  };
  constructor(_productId: string, _config: any) {
    this.config = _config;
    this.id = this.config.id;
    this.productId = _productId;
    this.name = this.config.name;

    this.topImagePath =
      "/product_image/" + this.productId + "/" + this.id + "-top.jpg";
    this.pinImagePath =
      "/product_image/" + this.productId + "/" + this.id + "-pin.png";
    this.imageSize = this.config.imageSize;
  }

  getScaledSize(googleMap: any) {
    if (this.imageSize) {
      return new googleMap.Size(this.imageSize.w, this.imageSize.h);
    }
    return new googleMap.Size(72, 80);
  }
  getIcon(googleMap: any) {
    return {
      url: this.pinImagePath,
      scaledSize: this.getScaledSize(googleMap),
    };
  }
}
