
import { defineComponent, ref } from "vue";
import { useParams, useToggle } from "@/utils/utils";
import { useStore } from "vuex";

import News from "../MapComponents/News.vue";

export default defineComponent({
  components: {
    News,
  },
  setup() {
    const store = useStore();
    const { productConfig } = useParams();

    const {
      value: isSwitchOpen,
      toggle: switchToggle,
      toggleOff: closeSwitch,
    } = useToggle(false);

    const switchItem = (key: number, id: string) => {
      switchToggle();
      store.commit("setItemKey", key);
    };

    const { value: isNewsOpen, toggle: newsToggle } = useToggle(false);

    const openNews = () => {
      switchToggle();
      newsToggle();
    };

    return {
      productConfig,
      isSwitchOpen,
      switchToggle,
      closeSwitch,
      switchItem,

      isNewsOpen,
      newsToggle,
      openNews,
      store,
    };
  },
});
