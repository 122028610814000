import {
  ref,
  reactive,
  computed,
  onUnmounted,
  watch,
  Ref,
  ComputedRef,
} from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import * as logUtil from "../utils/logUtil";

import { db } from "@/utils/firebase";
import { collectionGroup, query, onSnapshot, where } from "firebase/firestore";
import { doc2data, array2obj } from "@/utils/utils";

import { Loader } from "@googlemaps/js-api-loader";

import config from "@/config/config";

/* gaien
   const defaultCenter = {
   lat: 35.671132907519,
   lng: 139.71845855758
   };
*/
// tokyo station
/*
  const defaultCenter = {
  lat: 35.6809591,
  lng: 139.7673068
  };
*/
// musubu FM
/*
  const defaultCenter = {
  lat: 35.6449660554794,
  lng: 139.74902250648435,
  };
*/
// musubu tamachi
const defaultCenter = {
  lng: 139.74902250648435,
  lat: 35.6449660554794,
};

export const isDebug = config.target === "local";

export const getInitCurrentPosition = () => {
  const lat = localStorage.getItem("lat");
  const lng = localStorage.getItem("lng");
  return lat && lng ? { lat: Number(lat), lng: Number(lng) } : defaultCenter;
};

export const getMapConfig = (center: any) => {
  return {
    center,
    zoom: 17,
    minZoom: 7,
    // mapTypeControl: false
    disableDefaultUI: true,
    clickableIcons: false,
    styles: [
      {
        featureType: "poi.business",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi.school",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi.medical",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi.sports_complex",
        stylers: [{ visibility: "off" }],
      },
    ],
  };
};

export const initSearch = (
  docRef: any,
  googleMapApi: any,
  map: any,
  productId: string,
  itemId: Ref<string>,
  firebaseUser: any
) => {
  const input = docRef;
  const searchBox = new googleMapApi.maps.places.SearchBox(input);
  map.controls[googleMapApi.maps.ControlPosition.TOP_CENTER].push(input);
  input.addEventListener("focus", () => {
    input.value = "";
  });
  // Bias the SearchBox results towards current map's viewport.
  map.addListener("bounds_changed", () => {
    searchBox.setBounds(map.getBounds());
  });

  let markers: any[] = [];
  // Listen for the event fired when the user selects a prediction and retrieve
  // more details for that place.
  searchBox.addListener("places_changed", () => {
    const places = searchBox.getPlaces();

    if (places.length !== 1) {
      logUtil.searchHistory(
        input.value,
        productId,
        itemId.value,
        firebaseUser.uid,
        {}
      );
      return;
    }

    // Clear out the old markers.
    markers.forEach((marker) => {
      marker.setMap(null);
    });
    markers = [];

    // For each place, get the icon, name and location.
    const bounds = new googleMapApi.maps.LatLngBounds();
    places.forEach((place: any) => {
      logUtil.searchHistory(
        input.value,
        productId,
        itemId.value,
        firebaseUser.uid,
        place
      );
      if (!place.geometry) {
        console.log("Returned place contains no geometry");
        return;
      }
      const icon = {
        url: place.icon,
        size: new googleMapApi.maps.Size(72, 80),
        origin: new googleMapApi.maps.Point(0, 0),
        anchor: new googleMapApi.maps.Point(17, 34),
        scaledSize: new googleMapApi.maps.Size(24, 24),
      };

      // Create a marker for each place.
      markers.push(
        new googleMapApi.maps.Marker({
          map,
          icon,
          title: place.name,
          position: place.geometry.location,
        })
      );

      if (place.geometry.viewport) {
        // Only geocodes have viewport.
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    });
    map.fitBounds(bounds);
  });
};

export const initSearch2 = (
  docRef: any,
  googleMapApi: any,
  map: any,
  productId: string,
  itemId: Ref<string>,
  store: any,
  searchResult: Ref<any>
) => {
  const input = docRef;
  // for backward compatibility google
  input.addEventListener("focus", () => {
    input.value = "";
    searchResult.value = [];
  });

  input.addEventListener("input", (e: any) => {
    // console.log(store.state);
    searchResult.value = [];
    const keywords = input.value.split(/\s|　/).filter((v: string) => v !== "");

    // const shopList = Object.values(store.state.shopData).slice(0, 10);
    const shopList = Object.values(store.state.shopData);
    const len = shopList.length;

    if (keywords.length === 0) {
      return;
    }
    // const resultLists = [];
    for (let i = 0; i < len && searchResult.value.length < 10; i++) {
      const { address, shopName, prefectureName } = shopList[i] as any;

      const matched = keywords.filter((keyword: string) => {
        const isMatch = [address, shopName, prefectureName].some((v) =>
          v.includes(keyword)
        );
        return isMatch;
      });
      if (matched.length === keywords.length) {
        searchResult.value.push(shopList[i]);
      }
    }
    // TODO: if keyword exists and result is empty, search to google.
    // console.log(searchResult.value);
  });
  map.controls[googleMapApi.maps.ControlPosition.TOP_CENTER].push(input);
};

export const getCurrentPosition = (): Promise<GeolocationPosition> => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });
};

export const useFeedBacks = (
  firebaseUser: ComputedRef<any>,
  productId: string,
  dateToday: string,
  maxFeedbackNumber: number
) => {
  const feedbackObj = ref<{[key: string]: {[key: string]: any}}>({});

  let feedbackDetacher: null | (() => void) = null;
  const loadActionLog = () => {
    if (feedbackDetacher) {
      feedbackDetacher();
      feedbackDetacher = null;
    }
    feedbackDetacher = onSnapshot(
      query(
        collectionGroup(db, "actionLog"),
        where("userId", "==", firebaseUser.value.uid),
        where("productId", "==", productId),
        where("date", "==", dateToday),
        where("action", "==", "demand")
      ),
      (feedbackCollection) => {
        if (!feedbackCollection.empty) {
          const feedbacks = feedbackCollection.docs.map(doc2data);
          const feedbacksItem = feedbacks.reduce((tmp, current) => {
            if (tmp[current.itemId] === undefined) {
              tmp[current.itemId] = [];
            }
            tmp[current.itemId].push(current);
            return tmp;
          }, {});
          Object.keys(feedbacksItem).map((key) => {
            feedbacksItem[key] = array2obj(feedbacksItem[key], "shopId");
          });
          feedbackObj.value =  feedbacksItem;
        }
      }
    );
  };

  const feedbackCounter = computed(() => {
    return Object.keys(feedbackObj.value).reduce((tmp: {[key: string]: number}, key) => {
      tmp[key] = Object.keys(feedbackObj.value[key]).length;
      return tmp;
    }, {});
  });
  const archivedLimit = computed(() => {
    console.log("archivedLimit: " + feedbackCounter.value);
    return Object.keys(feedbackCounter.value).reduce((tmp: {[key: string]: boolean}, key) => {
      tmp[key] = feedbackCounter.value[key] >= maxFeedbackNumber;
      return tmp;
    }, {});
  });
  onUnmounted(() => {
    if (feedbackDetacher) {
      feedbackDetacher();
      feedbackDetacher = null;
    }
  });

  return {
    feedbackObj,
    archivedLimit,
    loadActionLog,
  };
};

export const useCurrentPin = (
  googleMapApi: any,
  map: any,
  productId: string,
  itemId: Ref<string>,
  firebaseUser: ComputedRef<any>
) => {
  const router = useRouter();

  const currentPin = ref();
  const isCurrentPosition = ref(false);
  const enableCurrentPositionButton = ref(true);

  const resetCurrentPin = () => {
    if (currentPin.value) {
      currentPin.value.setMap(null);
    }
    isCurrentPosition.value = false;
  };
  const centerChangeEvent = () => {
    resetCurrentPin();
    if (location.search && location.search.startsWith("?utm")) {
      const url = location.pathname;
      router.push(url);
    }
  };
  const setMarkerPin = (
    position: google.maps.LatLngLiteral,
    icon: google.maps.Icon
  ) => {
    return new googleMapApi.value.maps.Marker({
      position,
      map: map.value,
      icon,
    });
  };
  const setCurrentPin = (position: google.maps.LatLngLiteral) => {
    resetCurrentPin();
    currentPin.value = setMarkerPin(position, {
      url: "/images/current-pin.png",
      anchor: new googleMapApi.value.maps.Point(48, 48),
      scaledSize: new googleMapApi.value.maps.Size(96, 96),
    });
    isCurrentPosition.value = true;
  };

  const setCurrentPosition = async () => {
    enableCurrentPositionButton.value = false;
    const location = await getCurrentPosition();
    if (location && location.coords) {
      const latlng = new googleMapApi.value.maps.LatLng(
        location.coords.latitude,
        location.coords.longitude
      );
      map.value.panTo(latlng);
      map.value.setZoom(17);
      setCurrentPin({
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      });
      console.log("location:", location.coords);
      const geocoder = new google.maps.Geocoder();
      geocoder
        .geocode({
          location: {
            lat: location.coords.latitude,
            lng: location.coords.longitude,
          },
        })
        .then((response) => {
          const res = response.results[0];
          const { formatted_address } = res || {};
          logUtil.currentLog(
            location.coords.latitude,
            location.coords.longitude,
            formatted_address || "",
            productId,
            itemId.value,
            firebaseUser?.value?.uid || "--" // May break edge case
          );
        })
        .catch((e) => {
          console.log("error", e);
          logUtil.currentLog(
            location.coords.latitude,
            location.coords.longitude,
            "",
            productId,
            itemId.value,
            firebaseUser?.value?.uid || "---"
          );
        });
    }
    enableCurrentPositionButton.value = true;
  };

  return {
    isCurrentPosition,
    enableCurrentPositionButton,
    setCurrentPosition,
    centerChangeEvent,
  };
};
export const useGoogleMap = (apiKey: string, mapConfig: any) => {
  const map = ref();
  const google = ref();
  const googleMapRef = ref();
  const mapLoaded = ref(false);

  const initializeMap = async () => {
    const loader = new Loader({
      apiKey: apiKey,
      libraries: ["places"],
    });
    const googleMapApi = await loader.load();
    google.value = googleMapApi;
    map.value = new googleMapApi.maps.Map(googleMapRef.value, mapConfig);

    googleMapApi.maps.event.addListenerOnce(map.value, "idle", () => {
      mapLoaded.value = true;
    });
  };
  return {
    initializeMap,
    map,
    google,
    googleMapRef,
    mapLoaded,
  };
};

export const useItemConfigs = (productConfig: any) => {
  const store = useStore();
  const itemKey = computed(() => {
    return store.state.itemKey;
  });
  const itemConfig = computed(() => {
    return productConfig.items[itemKey.value];
  });
  const hasItemMenu = computed(() => {
    return productConfig && productConfig.hasItemMenu;
  });
  const itemId = computed(() => {
    return itemConfig.value.id;
  });
  return {
    itemKey,
    itemConfig,
    itemId,
    hasItemMenu,
  };
};
export const useUrl = (
  sharePathPrefix: string,
  productId: string,
  itemId: Ref<string>,
  shopKey: Ref<string>
) => {
  const itemUrlPath = computed(() => {
    return sharePathPrefix + "/" + productId + "/" + itemId.value;
  });
  const shopUrlPath = computed(() => {
    if (shopKey.value) {
      return itemUrlPath.value + "/map/s/" + shopKey.value;
    }
    return itemUrlPath.value;
  });

  return {
    shopUrlPath,
  };
};

export const useUrlParams = () => {
  const route = useRoute();
  const urlShopId = computed(() => {
    return route.params.shopId as string;
  });
  const urlItemId = computed(() => {
    return route.params.item as string;
  });
  return {
    urlShopId,
    urlItemId,
  };
};

export const useIsReady = () => {
  const isActive = ref(false);
  const isReady = ref(false);

  const showSplash = computed(() => {
    return !isReady.value || !isActive.value;
  });
  return {
    isActive,
    isReady,
    showSplash,
  };
};

export const dataFlatten = (data: {
  [key: string]: any;
}): { [key: string]: any } => {
  return Object.keys(data).reduce(
    (tmp: { [key: string]: any }, key: string) => {
      const current = data[key];
      return Object.assign(tmp, current);
    },
    {}
  );
};

export const useShowStore = () => {
  const storeShow = ref(false);
  const showStore = () => {
    storeShow.value = true;
  };
  const closeStore = () => {
    storeShow.value = false;
  };
  return {
    storeShow,
    showStore,
    closeStore,
  };
};

export const useShopData = (productConfig: any, itemConfig: any) => {
  const store = useStore();

  const inStockFlags = reactive<{ [key: string]: boolean }>({});

  const selectedShopKey = ref();
  const shopData = computed(() => {
    return store.state.shopData;
  });

  const selectedShop = computed(() => {
    return shopData.value[selectedShopKey.value];
  });
  // Bacause, when shared on android, there is no store data but it logs
  const existStockData = computed(() => {
    return inStockFlags[selectedShopKey.value] !== undefined
  });
  const currentStoreHasStock = computed(() => {
    return inStockFlags[selectedShopKey.value] || false;
  });
  // for android
  const meshMeta = computed(() => {
    if (store.state.shops && store.state.shops[itemConfig.value.id]) {
      return store.state.shops[itemConfig.value.id].data.mesh;
    }
    return {};
  });

  watch(selectedShopKey, (value) => {
    if (value && selectedShop.value) {
      document.title =
        selectedShop.value.shopName + "店/" + productConfig.headerTitle;
    } else {
      document.title = productConfig.headerTitle;
    }
  });
  return {
    selectedShopKey,
    inStockFlags,
    currentStoreHasStock,
    existStockData,
    shopData,
    selectedShop,
    meshMeta,
  };
};

export const getDefaultItemKey = (productConfig: any, urlItemId: string) => {
  const ret = productConfig.items.findIndex((a: any) => {
    return a.id === urlItemId;
  });
  return ret > -1 ? ret : 0;
};
