
import { defineComponent } from "vue";
import { useParams, params2quesryString } from "@/utils/utils";

export default defineComponent({
  setup() {
    const { productId, shareTopUrl } = useParams();

    const params = [
      {
        title: "店頭用",
        param: {
          utm_source: "store",
          utm_medium: "qrcode",
          utm_campaign: productId,
        },
      },
      {
        title: "SNS用",
        param: {
          utm_source: "sns",
          utm_medium: "display",
          utm_campaign: productId,
        },
      },
    ];
    const shareTopUrlQRCode = (params: {
      title: string;
      param: { [key: string]: string };
    }) => {
      return shareTopUrl + "?" + params2quesryString(params);
    };

    return {
      params,
      shareTopUrl,
      params2quesryString,
      shareTopUrlQRCode,
    };
  },
});
