import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
// import { RouteConfig, createRouter, createWebHistory } from 'vue-router';
import Map from "../views/Map.vue";
import Share from "../views/Share.vue";
import Logout from "../views/Logout.vue";
import LiffLayout from "../components/LiffLayout.vue";

import AppRouter from "../views/AppRouter.vue";
import Index from "../views/Index.vue";

import Term from "../views/term.vue";
import Privacy from "../views/privacy.vue";

import Links from "../views/Links.vue";

// import Redirect from '../views/Redirect.vue';
import config from "../config/config";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Index",
    component: Index,
    meta: { layout: "none" },
  },
  // just for share page. not liff.
  {
    path: "/share",
    name: "Share",
    component: Share,
  },
  {
    path: "/:product/share",
    name: "ProdShare",
    component: Share,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
  },
  {
    path: "/:product/links",
    name: "Links",
    component: Links,
  },
  {
    path: "/:product/privacy",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/:product/term",
    name: "Term",
    component: Term,
  },
  {
    path: "/liff/:product/privacy",
    name: "Privacy2",
    component: Privacy,
  },
  {
    path: "/liff/:product/term",
    name: "Term2",
    component: Term,
  },
  {
    path: "/*",
    component: AppRouter,
    children: [
      {
        path: "/liff",
        component: LiffLayout,
        children: [
          {
            path: "/",
            name: "LiffHome",
            component: Map,
          },
          {
            path: ":product",
            name: "ProductHome",
            component: Map,
          },
          {
            path: ":product/:item",
            name: "ProductItemHome",
            component: Map,
          },
          {
            path: ":product/:item/s/:shopId?",
            name: "ProductShopMap",
            component: Map,
          },
          {
            path: ":product/:item/map/s/:shopId?",
            name: "ProductShopMap2",
            component: Map,
          },
        ],
      },
      // actual share page. ogp and just redirext page.
      {
        path: "/:product",
        name: "MapShopP",
        component: Map,
        // component: Map,
      },
      {
        path: "/:product/:item",
        name: "MapShopPS",
        component: Map,
        // component: Map,
      },
      {
        path: "/:product?/map/s/:shopId?",
        name: "MapShop",
        component: Map,
      },
      {
        path: "/:product?/:item/map/s/:shopId?",
        name: "MapShopSMI",
        component: Map,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
