
import { defineComponent } from "vue";

import liff from "@line/liff";
import { useParams } from "../utils/utils";

import { auth } from "@/utils/firebase";
import { signOut } from "firebase/auth";

export default defineComponent({
  setup() {
    const { productConfig } = useParams();

    liff.init({ liffId: productConfig.liffId }).then(async () => {
      signOut(auth);
      liff.logout();
    });
  },
});
