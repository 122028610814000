
import { defineComponent } from "vue";
import { useParams, useFirestoreUser } from "../utils/utils";

import * as logUtil from "../utils/logUtil";

export default defineComponent({
  setup() {
    const { productId, productConfig } = useParams();
    const { firebaseUser } = useFirestoreUser();

    logUtil.appLog(productId, firebaseUser.value?.uid || "none", "term");
    return {
      productConfig,
    };
  },
});
