import queryString from "query-string";

// internal func. export is for test.
export const parseLiffState = (liffstate: string) => {
  if (!liffstate) return {};
  const splited = liffstate.split("?");
  if (splited.length < 2) {
    return {
      liffStatePath: splited[0] || "",
      liffStateQuery: {},
    };
  }
  return {
    liffStatePath: splited[0],
    liffStateQuery: queryString.parse(splited[1] as string),
  };
};

export const getSharePathPrefix = (locationPath: string) => {
  const sharePathPrefix = locationPath.startsWith("/liff") ? "/liff" : "";
  return sharePathPrefix;
};

export const getSharePath = (locationPath: string) => {
  const sharePathPrefix = getSharePathPrefix(locationPath);
  return locationPath.slice(sharePathPrefix.length);
};

export const getShareLiffPath = (
  liffStatePath: string | undefined,
  liffStateQuery: any,
  sharePath: string
) => {
  // if (liffStateQuery && !liffStateQuery["redirect"]) {
  if (liffStatePath) {
    return liffStatePath.replace(/^\//, "");
  }
  // shared path is /bataco/coffee/map/s/123
  const paths = sharePath.split("/").filter((a: string) => a !== "");
  paths.shift();
  // return /coffee/map/s/123 because /bataco is liff url.
  return paths.join("/");
};
