
import { defineComponent } from "vue";
import Icons from "./Icons.vue";

export default defineComponent({
  components: {
    Icons,
  },
  props: {
    selectedShop: {
      type: Object,
      required: true,
    },
  },

  setup(props, context) {
    const externalLink = () => {
      context.emit("externalLink");
    };
    return {
      externalLink,
    };
  },
});
