import products from './products';

const config = {
  host: "https://productslocator.com",
  target: "prod",
  bucketName: "productslocator-fe61c.appspot.com",
  line: {
    liffId: '1655623984-bdwB9m7Z',
  },
  google: {
    mapApiKey: 'AIzaSyA3-IWGhSoigb6P66_lgqhM9w_xZyQ-KJE', // prod
  },
  firebaseConfig: {
    apiKey: "AIzaSyD3s0BhIOB4hdIs12TwmvObZUPIFYXNt_E",
    authDomain: "productslocator-fe61c.firebaseapp.com",
    projectId: "productslocator-fe61c",
    storageBucket: "productslocator-fe61c.appspot.com",
    messagingSenderId: "629888757569",
    appId: "1:629888757569:web:90f30bca03daafb24e3bc0",
    measurementId: "G-ZYKTQEM7QP"
  },
  products,
  shopData: "data/shopData.json",
  meshShopData: "data/mesh.json",
};

export default config;
